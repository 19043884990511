<template>
  <x-card
    class="stock-prices"
    size="xl"
  >
    <template v-if="isPermitByTariff">
      <div
        v-if="isLoading"
        class="stock-prices__loader"
      >
        <loader />
      </div>

      <template v-else>
        <grid
          auto
          :cells="tasks"
          :columns="columns"
          class="stock-prices__grid"
        >
          <template #created="{ created }">
            {{ formatDate(created, 'DD.MM.YYYY HH:mm') }}
          </template>

          <template #status="{ row, status }">
            <badge
              :status="status"
              :status-list="statusList"
              :icon="statusIcons[status]"
              :status-tooltips="{ error: row.error }"
            />
          </template>

          <template #logUrl="{ row }">
            <download-button
              :disabled="isDownloadBtnBlocked"
              color="gray"
              :file-name="`Лог ошибок (${formatDate()})`"
              @click.native="onDownloadClick(row.id)"
            />
          </template>
        </grid>

        <div
          v-if="tasks.length"
          class="stock-prices__pagination"
        >
          <pagination
            :data="pagination"
            :current-page="currentPage"
            @change="onPaginationChange"
          />
        </div>

        <x-placeholder
          v-else
          icon="table-empty"
          desc="История обновления цен и остатков отсутствует"
        />
      </template>
    </template>
    <not-permit-by-tariff v-else />
  </x-card>
</template>

<script>
import Grid from '@/components/Interface/Grid.vue'
import Badge from '@/components/Common/Badge.vue'
import Loader from '@/components/Common/Loader.vue'
import Pagination from '@/components/Interface/Pagination.vue'
import DownloadButton from '@/components/Interface/DownloadButton.vue'
import PaginationMixin from '@/mixins/pagination'
import columns, { statusList, statusIcons } from '@/constants/stock-prices'
import formatDate from '@/utils/date-formatter'
import { createNamespacedHelpers } from 'vuex'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff'

const { mapActions, mapState } = createNamespacedHelpers('stocks')
const { mapActions: mapTrackingActions } = createNamespacedHelpers('tracking')

export default {
  mixins: [PaginationMixin],
  components: {
    NotPermitByTariff,
    Grid,
    Badge,
    Loader,
    Pagination,
    DownloadButton
  },

  data () {
    return {
      columns,
      statusList,
      statusIcons,
      isLoading: false,
      fetchTimerId: null,
      isDownloadBtnBlocked: false
    }
  },

  computed: {
    ...mapState(['tasks']),

    isPermitByTariff () {
      return this.$user.isPermitByTariff(PERMISSION_KEY.SELLER_PRICES_STOCKS)
    }
  },

  created () {
    this.fetchTasks()
    this.setTasksFetchInterval()
  },

  beforeDestroy () {
    clearInterval(this.fetchTimerId)
  },

  methods: {
    ...mapActions(['getTasks', 'downloadStockXLSX']),
    ...mapTrackingActions(['setEvent']),

    formatDate,

    async fetchTasks (params, background = false) {
      try {
        if (!background) {
          this.isLoading = true
        }

        const payload = {
          page: this.currentPage,
          ...this.pagination,
          ...params
        }
        this.pagination.count = await this.getTasks(payload)
      } finally {
        this.isLoading = false
      }
    },

    setTasksFetchInterval () {
      this.fetchTimerId = setInterval(() => {
        this.fetchTasks({}, true)
      }, 10000)
    },

    onPaginationChange ({ page, limit, offset }) {
      this.currentPage = page
      this.pagination.limit = limit

      this.fetchTasks({ page, limit, offset })
    },

    async onDownloadClick (taskId) {
      this.isDownloadBtnBlocked = true
      await this.downloadStockXLSX(taskId)
      this.isDownloadBtnBlocked = false

      this.setEvent({
        name: 'mmp_stockhistory_download',
        product: 'MMP',
        screen: 'mmp_stockhistory'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .stock-prices
    &__loader
      text-align center

    &__grid
      margin-bottom 20px
</style>
