export default Object.freeze([{
  name: 'ID',
  key: 'id'
}, {
  name: 'Дата создания',
  key: 'created',
  type: 'created'
}, {
  name: 'Магазин',
  key: 'shop_name'
}, {
  name: 'Тип операции',
  key: 'name'
}, {
  name: 'Источник',
  key: 'source'
}, {
  name: 'Автор',
  key: 'author_email'
}, {
  name: 'Статус',
  key: 'status',
  type: 'status'
}, {
  name: 'Скачать лог',
  key: 'log_url',
  type: 'logUrl'
}])

export const statusList = Object.freeze({
  scheduled: 'Запланирована',
  running: 'В работе',
  success: 'Завершен',
  error: 'Ошибка'
})

export const statusIcons = Object.freeze({
  scheduled: '',
  running: 'loader--mini',
  success: 'success-fill',
  error: 'error-triangle'
})
