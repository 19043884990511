var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-card',{staticClass:"stock-prices",attrs:{"size":"xl"}},[(_vm.isPermitByTariff)?[(_vm.isLoading)?_c('div',{staticClass:"stock-prices__loader"},[_c('loader')],1):[_c('grid',{staticClass:"stock-prices__grid",attrs:{"auto":"","cells":_vm.tasks,"columns":_vm.columns},scopedSlots:_vm._u([{key:"created",fn:function(ref){
var created = ref.created;
return [_vm._v(" "+_vm._s(_vm.formatDate(created, 'DD.MM.YYYY HH:mm'))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
var status = ref.status;
return [_c('badge',{attrs:{"status":status,"status-list":_vm.statusList,"icon":_vm.statusIcons[status],"status-tooltips":{ error: row.error }}})]}},{key:"logUrl",fn:function(ref){
var row = ref.row;
return [_c('download-button',{attrs:{"disabled":_vm.isDownloadBtnBlocked,"color":"gray","file-name":("Лог ошибок (" + (_vm.formatDate()) + ")")},nativeOn:{"click":function($event){return _vm.onDownloadClick(row.id)}}})]}}],null,false,3670433184)}),(_vm.tasks.length)?_c('div',{staticClass:"stock-prices__pagination"},[_c('pagination',{attrs:{"data":_vm.pagination,"current-page":_vm.currentPage},on:{"change":_vm.onPaginationChange}})],1):_c('x-placeholder',{attrs:{"icon":"table-empty","desc":"История обновления цен и остатков отсутствует"}})]]:_c('not-permit-by-tariff')],2)}
var staticRenderFns = []

export { render, staticRenderFns }